
import {defineComponent, ref, watch} from 'vue'
import ChartService from "@/core/services/ChartService";
import QuickAction from "@/components/base/action/QuickAction.vue";
import {LoadPanel} from "@/core/composite/composite";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import Swal from "sweetalert2";
import {makeDownloadLink} from "@/core/helpers/functions";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "DataTable",
  components: {EntityLoading, DateTimeFormat, QuickAction},
  props: {
    params: {type: Object, required: true },
    showIfEmpty: {type: Boolean, default: true},
    clazz : {type:String, default:""},
    viewMoreLink: {type: String, default: ""},
    isDashboardWidget: {type: Boolean, default: false} // New prop to control scrolling behavior for home page and dashboards
  },
  setup(props) {
    const model = ref(props.params)
    const loading = ref(true);
    const chart = ref<any>({});

    const loadChart = (request) => {
      loading.value = true;
      ChartService.create(request).then(res => {
        console.log(res);
        chart.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    loadChart(model.value);
    watch(() => props.params, (val) => {
      model.value = val
      loadChart(model.value)
    })
    const {t} = useI18n();
    return {
      t,
      model,
      loading,
      chart,
      ...LoadPanel(),
    }
  },
  methods: {
    exportTo(type) {
      this.model.format = type;
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      ChartService.export(this.model).then(res => {
        makeDownloadLink(res);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    }
  }
})
